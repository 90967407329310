export const NavbarData = [
    {
        key: 1,
        text: "Home",
        link: "#",
    },
    {
        key: 2,
        text: "Projects",
        link: "#projects",
    },
    {
        key: 3,
        text: "Contact",
        link: "#contact",
    }
] 
