import './Home.css'

export const Home = () => {
    return(
        <section className='home'>
            <a id=""></a>
            <div className="name">
                <h2>Cole Barraclough</h2>
            </div>
            <div className="description">
                <h6 className='cringe-tags'>Software Engineering Student  •  Hobby Game Developer</h6>
            </div>
        </section>
    );
}